<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";

export default {
  page: {
    title: "Item Wise Report",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect, DatePicker },
  data() {
    return {
      tableData: [],
      tableData2: [],
      isReportTableBusy: false,

      title: "Item Wise Report",
      items: [
        {
          text: "View",
        },
        {
          text: "Item Wise Report",
          active: true,
        },
      ],
      restaurants: [],
      branches: [],
      cityArr: [],
      city: "",
      topSellingProducts: [],
      daterange: [],
      countryArr: [],
      country: "",
      brandArr: [],
      brandData: "",
      restBranchID: this.$storageData.profile.restBranchID,

      // restaurantID:(sessionStorage.getItem('restID')>0)? [{'restID':sessionStorage.getItem('restID'),'name':sessionStorage.getItem('restName')}] : "",
      // branchID:(sessionStorage.getItem('branchID')>0) ? [{'restBranchID':sessionStorage.getItem('branchID'),'branchName':sessionStorage.getItem('branchName')}] : "",
      restaurantID: "",
      branchID: "",

      // datatable 1 & 2 ///////
      sortBy: "age",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      sortDesc: false,
      fields: [
        {
          key: "itemName",
          sortable: true,
        },
        {
          key: "restName",
          sortable: true,
        },
        {
          key: "restBranchName",
          sortable: true,
        },
        {
          key: "foodType",
          sortable: true,
        },
        {
          key: "category",
          sortable: true,
        },
        {
          key: "totalItemPrice",
          sortable: true,
          tdClass: "align-right",
        },

        {
          key: "totalItemTax",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "totalItemQuantity",
          sortable: true,
          tdClass: "align-right",
        },
        {
          key: "totalRevenue",
          sortable: true,
          tdClass: "align-right",
        },
      ],
      ////////// datatable 3 category //////////////////

      // datatable 1 & 2 ///////
      sortBy2: "age",
      totalRows2: 1,
      currentPage2: 1,
      perPage2: 10,
      pageOptions2: [10, 25, 50, 100],
      filter2: null,
      filterOn2: [],
      sortDesc2: false,
      companyArr: [],
      companyID: 0,
      fields2: [
        {
          key: "category",
          sortable: true,
        },
        {
          key: "orders",
          sortable: true,
        },

        {
          key: "totalItemPrice",
          sortable: true,
        },
        {
          key: "totalItemTax",
          sortable: true,
        },
        {
          key: "totalRevenue",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    rows2() {
      return this.tableData2.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    // this.readRestaurantMenu();
    // this.getRestaurantList();

    if (this.$storageData.profile.empTypeID == 1) {
      this.getAllCountry();
    } else if (
      this.$storageData.profile.empTypeID == 2 ||
      this.$storageData.profile.empTypeID == 11
    ) {
      this.getRestaurantCountry();
    }
    // else{
    this.getProductMixReportByValue(1);
    // }
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getAllCountry() {
      this.countryArr = [];
      this.country = "";
      this.axios.post(this.$loggedRole+"/getAllCountry").then((response) => {
        this.countryArr = response.data.data;
        // this.country = (this.countryArr) ? this.countryArr[0] : "";
        this.getAllCities();
      });
    },
    getAllCities() {
      this.cityArr = [];
      this.city = "";
      this.axios
        .post(this.$loggedRole+"/getAllCities", {
          country: this.country ? this.country.country : "",
        })
        .then((response) => {
          this.cityArr = response.data.data;
          // this.city = (this.cityArr) ? this.cityArr[0] : "";
          if (this.$storageData.profile.empTypeID == 1) {
            // super admin
            // this.getRestaurantBrands();
            this.getAllCompanies();
          }
          if (this.$storageData.profile.empTypeID == 11) {
            // food company login
            this.getAllCompanies();

            this.getRestaurantBrandsByCompanyID();
          } else {
            this.readRestaurantBranchData();
          }
        });
    },

    onFiltered2(filteredItems2) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows2 = filteredItems2.length;
      this.currentPage2 = 1;
    },
    getRestaurantBrands() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantList", {
          restID: this.$storageData.profile.restID,
          restBranchID: this.$storageData.profile.restBranchID,
          empTypeID: this.$storageData.profile.empTypeID,
        })
        .then((result) => {
          this.brandArr = result.data.data;
          this.brandData = this.brandArr ? this.brandArr[0] : "";
          this.readRestaurantBranchData();
        });
    },
    getRestaurantBrandsByCompanyID() {
      this.brandArr = [];
      this.brandData = "";
      var companyID = this.companyID;
      if (this.$storageData.profile.empTypeID == 11) {
        companyID = this.$storageData.profile.companyID;
      }
      this.axios
        .post(this.$loggedRole+"/getRestaurantBrandsByCompanyID", { companyID: companyID })
        .then((response) => {
          this.brandArr = response.data.data;
          // this.brandData = (this.brandArr) ? this.brandArr[0] : "";
          this.readRestaurantBranchData();
        });
    },
    getRestaurantCountry() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantCountry", { restID: this.$storageData.profile.restID })
        .then((response) => {
          this.countryArr = response.data.data;
          this.country = this.countryArr ? this.countryArr[0] : "";
          this.getAllCompanies();
        });
    },
    getRestaurantCities() {
      var restID = this.$storageData.profile.restID;
      if (this.brandData.restID && this.brandData.restID != "") {
        restID = this.brandData.restID;
      }
      this.axios
        .post(this.$loggedRole+"/getRestaurantCities", {
          restID: restID,
          country: this.country ? this.country.country : "",
        })
        .then((response) => {
          this.cityArr = response.data.data;
          this.city = this.cityArr ? this.cityArr[0] : "";
          if (this.$storageData.profile.empTypeID == 11) {
            this.getRestaurantBrandsByCompanyID();
          } else {
            this.readRestaurantBranchData();
          }
        });
    },
    getAllCompanies() {
      this.companyArr = [];
      this.companyID = "";
      var companyID = 0;
      if (this.$storageData.profile.empTypeID == 11) {
        companyID = this.$storageData.profile.companyID;
      }
      this.axios
        .post(this.$loggedRole+"/getAllCompanies", { companyID: companyID })
        .then((response) => {
          this.companyArr = response.data.data;
          // this.companyID = (this.companyArr) ? this.companyArr[0] :"";
          //  this.branchID = (this.branches) ? this.branches[0] :"";
          //  this.restBranchID = (this.branchID) ? this.branchID.restBranchID: "";
          //  this.getProductMixReport();
        });
    },
    readRestaurantBranchData() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantBranchByRestID", {
          restID:
            this.brandData != ""
              ? this.brandData.restID
              : this.$storageData.profile.restID,
          restBranchID: 0,
          empTypeID: this.$storageData.profile.empTypeID,
          city: this.city ? this.city.city : "",
        })
        .then((response) => {
          this.branches = response.data.data;
          this.branchID = this.branches ? this.branches[0] : "";
          this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
          //  this.getProductMixReportByValue(1);
        });
    },
    onChangeRestBrand() {
      this.readRestaurantBranchData();
      this.getRestaurantCities();
    },
    onchangeBranch() {
      this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
      //  this.getProductMixReportByValue(1);
    },
    applyFilter() {
      if (this.daterange.includes(null)) {
        this.daterange = [];
      }
      this.getProductMixReport();
      this.getProductMixReportByValue(1);
    },
    clearFilter() {
      this.daterange = [];
      this.brandData = "";
      this.restBranchID = "";
      this.country = "";
      this.companyID = "";
      this.brandData = "";
      this.city = "";
      this.branchID = "";
      this.getProductMixReport();
      this.getProductMixReportByValue(1);
    },

    // getProductMixReport(){
    //       this.axios.post(this.$loggedRole+"/productMixReport",
    //         {'restID':(this.brandData!="") ? this.brandData.restID : this.$storageData.profile.restID,
    //             'restBranchID':this.restBranchID,
    //             'empTypeID':this.$storageData.profile.empTypeID,
    //             'daterange':this.daterange,
    //         })
    //           .then((result)=>{

    //             this.topSellingProducts = result.data.data;

    //         });
    //   },

    // changes done by Mohammed on 13-12-2021
    getProductMixReportByValue(flag) {
      this.isReportTableBusy = true;
      this.axios
        .post(this.$loggedRole+"/itemWiseSalesReport", {
          restID:
            this.brandData != ""
              ? this.brandData.restID
              : this.$storageData.profile.restID,
          restBranchID: this.restBranchID,
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          daterange: this.daterange,
          flag: flag,
        })
        .then((response) => {
          this.tableData = response.data.data;
          this.topSellingProducts = response.data.graphData;
          this.isReportTableBusy = false;
        });
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" />
    <!--FILTER START------>
    <div class="card" style="margin-top:-30px">
      <div class="card-body">
        <div class="inner mb-2 row">
          <!-- Country List-->
          <div
            class="col-md-2"
            style="width:10%"
            v-if="
              this.$storageData.profile.empTypeID == 1 ||
                this.$storageData.profile.empTypeID == 2 ||
                this.$storageData.profile.empTypeID == 11
            "
          >
            <label>Country </label>
            <multiselect
              v-model="country"
              :options="countryArr"
              track-by="country"
              label="country"
              @input="getAllCompanies()"
              :show-labels="false"
            ></multiselect>
          </div>
          <!-- Country List END-->
          <!-- Company List-->
          <div
            class="col-md-2"
            style="width:16%"
            v-if="
              this.$storageData.profile.empTypeID == 1 ||
                this.$storageData.profile.empTypeID == 11
            "
          >
            <label>Company</label>
            <multiselect
              v-model="companyID"
              :options="companyArr"
              track-by="companyID"
              label="companyName"
              @input="getRestaurantBrandsByCompanyID()"
              :show-labels="false"
            ></multiselect>
          </div>
          <!-- Compnay List END-->
          <!-- Brand List-->
          <div
            class="col-md-2"
            style="width:16%"
            v-if="
              this.$storageData.profile.empTypeID == 1 ||
                this.$storageData.profile.empTypeID == 11
            "
          >
            <label>Restaurant Brand</label>
            <multiselect
              v-model="brandData"
              :options="brandArr"
              track-by="restID"
              label="name"
              @input="getRestaurantCities()"
              :show-labels="false"
            ></multiselect>
          </div>
          <!-- Brand List END-->
          <!-- Cities List-->
          <div
            class="col-md-2"
            style="width:12%"
            v-if="
              this.$storageData.profile.empTypeID == 1 ||
                this.$storageData.profile.empTypeID == 2 ||
                this.$storageData.profile.empTypeID == 11
            "
          >
            <label>City </label>
            <multiselect
              v-model="city"
              :options="cityArr"
              track-by="city"
              label="city"
              @input="readRestaurantBranchData()"
              :show-labels="false"
            ></multiselect>
          </div>
          <!--END Cities List-->
          <!-- Branch List-->
          <div
            class="col-md-2"
            style="width:16%"
            v-if="
              this.$storageData.profile.empTypeID == 1 ||
                this.$storageData.profile.empTypeID == 2 ||
                this.$storageData.profile.empTypeID == 11
            "
          >
            <label>Restaurant Branch </label>
            <multiselect
              v-model="branchID"
              :options="branches"
              track-by="restBranchID"
              label="branchName"
              @input="onchangeBranch()"
              :show-labels="false"
            ></multiselect>
          </div>
          <!-- Branch List END-->
          <!-- Date range -->
          <div class="col-md-2" style="width:20%">
            <label>Date Range </label>
            <date-picker
              v-model="daterange"
              format="DD MMM Y"
              range
              append-to-body
              lang="en"
              confirm
              @input="onchangeBranch()"
            ></date-picker>
          </div>
          <!-- Date range END-->

          <!-- Apply filter -->
          <div class="col-md-2" style="width:auto">
            <button class="btn btn-themeOrange mt-4" v-on:click="applyFilter()">
              Apply Filter
            </button>
          </div>
          <!-- Apply filter END-->

          <!-- Clear filter -->
          <div class="col-md-2" style="width:10%">
            <button class="btn btn-themeBrown mt-4" v-on:click="clearFilter()">
              Clear Filter
            </button>
          </div>
          <!-- Clear filter END-->
        </div>
      </div>
    </div>
    <!--FILTER END------>

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <b-tabs
            justified
            nav-class="nav-tabs-custom"
            content-class="p-3 text-muted"
          >
            <b-tab @click="getProductMixReportByValue(1)" active>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block">Overview</span>
              </template>

              <div class="row">
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <h6>Top Selling Products</h6>
                      <apexchart
                        class="apex-charts"
                        height="350"
                        type="bar"
                        dir="ltr"
                        :series="topSellingProducts.series"
                        :options="topSellingProducts.chartOptions"
                      ></apexchart>
                    </div>
                  </div>
                </div>
              </div>
              <!----START OF PRODUCT BY VALUE---->
            </b-tab>
            <b-tab @click="getProductMixReportByValue(1)">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-user"></i>
                </span>
                <span class="d-none d-sm-inline-block">List</span>
              </template>
              <div class="row">
                <div class="col-12">
                  <div class="row mt-4">
                    <div class="col-sm-12 col-md-4">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Show &nbsp;&nbsp;
                          <b-form-select
                            style="margin-left:5px; margin-right:5px"
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                          ></b-form-select
                          >&nbsp;&nbsp;&nbsp; entries
                        </label>
                      </div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-4 row">
                      <div
                        id="tickets-table_filter"
                        class="dataTables_filter text-md-end"
                      >
                        <label class="d-inline-flex align-items-center">
                          Search:
                          <b-form-input
                            v-model="filter"
                            type="search"
                            placeholder="Search..."
                            class="form-control form-control-sm ms-2"
                          ></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <!-- Table -->
                  <div class="table-responsive mb-0">
                    <b-table
                      striped
                      outlined
                      bordered
                      :items="tableData"
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                      fixed-header
                      head-variant="light"
                      fixed
                      :busy="isReportTableBusy"
                    >
                      <template #table-busy>
                        <div class="text-center text-danger my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>Loading...</strong>
                        </div>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-end"
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!----END OF PRODUCT BY VALUE---->
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style lang="scss" scoped>
/** or use this class: table thead th.bTableThStyle { ... } */
.table .bTableThStyle {
  max-width: 12rem !important;
  text-overflow: ellipsis !important;
  background: #000;
}
</style>
